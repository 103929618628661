.node-card {
  @apply flex flex-col w-full;

  @include responsive(md) {
    padding: 0 1.5rem;
  }

  @include responsive(xl) {
    padding: 0 4rem;
  }

  &__img {
    @apply relative;
    height: calc-rem(250);
    margin-bottom: 2rem;

    @include responsive(lg) {
      margin-bottom: calc-rem(55);
    }

    @include responsive(xl) {
      height: calc-rem(330);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}